@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Vollkorn&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", "Vollkorn" sans-serif;
}

.navbar {
  background-color: #fff !important;
}

.navbar-brand {
  font-size: 1.9rem !important;
  color: #565387 !important;
  font-family: "Vollkorn";
}

.navbar a {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover {
  color: #3498db !important;
}

@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

.homepage {
  font-family: "Vollkorn"
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */


#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  border-radius: 50%;
  width: 70%;
  border: 1px solid #000000
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* ////////Contact ////////////
 */

.contact_div {
  width: 100%;
  height: 75vh;
}

/* /// footer///////////////////// */

footer p {
  padding: 0;
  margin: 0;
  position: relative;
}

/* responsive code */

@media (max-width: 991px) {
  #header {
    /* height: 80vh; */
    height: auto;
    text-align: center;
  }

  #header .header-img {
    text-align: center;
  }

  #header .header-img img {
    width: 60%;
  }

  #header .intro_img {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }

  #header h1 {
    font-size: 28px;
  }

  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }

  #header .header-img img {
    width: 70%;
    border-radius:50%;
    overflow: hidden;
    position: relative;
  }

  #header .intro_img {
    width: 70%;
  }
}

.col-center {
  margin: 0 auto;
  float: none !important;
}

.carousel {
  margin: 50px auto;
  padding: 0 70px;
}

.carousel-item {
  color: #999;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  min-height: 290px;
}

.carousel .item .img-box {
  width: 135px;
  height: 135px;
  margin: 0 auto;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}

.carousel .testimonial {
  padding: 30px 0 10px;
}

.carousel .overview {
  font-style: italic;
}

.carousel .overview b {
  text-transform: uppercase;
  color: #db584e;
}

.carousel .carousel-control {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  top: 50%;
  background: none;
}

.carousel-control i {
  font-size: 68px;
  line-height: 42px;
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}

.carousel .carousel-indicators {
  bottom: -40px;
}

.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  margin: 1px 3px;
  border-radius: 50%;
}

.carousel-indicators li {
  background: #999;
  border-color: transparent;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}

.carousel-indicators li.active {
  background: #555;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6 {}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 60px 0;
  height: auto;
  /* min-height: 100vh;*/
}

.footer {
  background: linear-gradient(105deg, #6e99e6, #093c94);
  padding-top: 80px;
  padding-bottom: 40px;
}

/*END FOOTER SOCIAL DESIGN*/
.single_footer {}

@media only screen and (max-width:768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}

.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

.single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 20px;
}

.single_footer p {
  color: #fff;
}

.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single_footer ul li {}

.single_footer ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
}

.single_footer ul li a:hover {
  color: #ff3666;
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
  color: #fff;
}

.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}

.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}

.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

@media only screen and (max-width:768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.subscribe__btn i {
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #ff3666;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}

.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.social_profile ul li {
  float: left;
}

.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width:480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.social_profile ul li a:hover {
  background: #ff3666;
  border: 1px solid #ff3666;
  color: #fff;
  border: 0px;
}

/*END SOCIAL PROFILE CSS*/
.copyright {
  margin-top: 70px;
  padding-top: 40px;
  color: #fff;
  font-size: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}

.copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}

.copyright a:hover {
  color: #ff3666;
}

.intro {
  color: #555;
}

#header .intro_img {
  border-radius: 50%;
}

.intro_img_div {

  border-radius: 50%;
  text-align: right;
}

#header .intro_img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  border-radius: 50%;
  text-align: right;
  margin: 0 auto;
  padding: 5px;
  border: 1px solid #050505;
}

.carousel-indicators li {
  background-color: #999 !important;
  background-color: rgba(70, 70, 70, .25) !important;
}

.carousel-indicators .active {
  background-color: #444 !important;
}

.carousel-control-next-icon {
  color: #040404 !important;
  background-color: rgba(149, 190, 218, 0.25) !important;
}

.carousel-control-prev-icon {
  color: #040404 !important;
  background-color: rgba(149, 190, 218, 0.25) !important;
}